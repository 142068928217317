import { CreateModal as GenericCreateModal } from '@admin/organisms/CreateModal';
import { getRoutePath, Route } from '@admin/routing';
import { NewsActionCreate, NewsCategory } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { createNews, formFields } from '../../helpers';
import { ArticleItem, NewsActionCreateItem } from '../../types';

interface Props {
    categories: NewsCategory[];
    onSuccess: () => void;
    __translationKey: string;
}

export const CreateModal = ({ categories, onSuccess, __translationKey }: Props) => {
    const contextData = useContextData();

    const getCategoryID = (slug: string) => {
        const category = categories.find((catobj: NewsCategory) => catobj.slug === slug);
        return category?.id;
    };

    const submit = async (data: NewsActionCreateItem): Promise<void> => {
        const requestBody: NewsActionCreate = {
            newsTitle: data.newsTitle,
            categoryID: getCategoryID(data.category),
            commentCount: 0,
        };

        return await createNews({
            contextData,
            requestBody,
        }).then((response) => {
            if (response.newsID) {
                window.location.href = `${getRoutePath(response?.category?.isMedia ? Route.Media : Route.Article)}/${
                    response?.newsID
                }?active=1`;
            }
        });
    };

    return (
        <GenericCreateModal<ArticleItem, NewsActionCreateItem>
            onSuccess={onSuccess}
            formFields={formFields(categories)}
            submit={submit}
            __translationKey={__translationKey}
        />
    );
};

import { News, NewsActionCreate, NewsService } from '@common/clients/api';
import { ApiService } from '@common/clients/request';
import { ContextData } from '@common/defaults';

export async function createNews({
    requestBody,
    contextData,
}: {
    requestBody: NewsActionCreate;
    contextData: ContextData;
}): Promise<News> {
    return ApiService<NewsService>({
        contextData,
        isClientSide: true,
        service: NewsService,
    }).createNewsByDomainId({
        contextId: contextData.context.id,
        requestBody,
    });
}
